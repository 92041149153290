import React from "react";

import {
  blue,
  doubleSpace,
  SectionHeader,
  SectionSubheader,
  singleSpace,
  tripleSpace,
} from "components/sharedComponents";
import styled from "styled-components";

export const ThreePartHeader = ({ subtitle, title, type, ...rest }) => {
  return (
    <StyledThreePartHeader {...rest}>
      <SectionSubheader>{subtitle}</SectionSubheader>
      <SectionHeader>{title}</SectionHeader>
      <SectionDivider className={`divider ${type}`} />
    </StyledThreePartHeader>
  );
};

const StyledThreePartHeader = styled.div`
  text-align: center;
  h2 {
    margin: 0 auto ${doubleSpace};
  }
  h4 {
    margin-bottom: ${singleSpace};
    font-weight: 600;
  }
  .divider {
    margin-bottom: ${tripleSpace};
  }
`;

const SectionDivider = styled.div`
  height: 4px;
  background-color: ${blue};
  width: 165px;
  margin: 0 auto;

  &.white {
    background-color: white;
    height: 3px;
  }
`;
