import React from "react";

import { formatDateTime } from "utils/dateTime";

import { SectionSubheader } from "components/sharedComponents";

export const Greeting = ({ confirmation }) => {
  const { month, day, hours, minutes, period } = formatDateTime(confirmation.shift.start_date);

  return (
    <div>
      <SectionSubheader>
        Hi {confirmation.provider.first_name}! It looks like you have a shift coming up on {month}{" "}
        {day} at {hours}:{minutes} {period}.
      </SectionSubheader>
      <SectionSubheader>
        Please check the shift details below and confirm you can make it.
      </SectionSubheader>
    </div>
  );
};
