import React from "react";

import BugsnagPerformance from "@bugsnag/browser-performance";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

// Initialize Bugsnag outside the component to avoid re-initialization
let ErrorBoundary;

const initializeBugsnag = () => {
  Bugsnag.start({
    apiKey: `${process.env.REACT_APP_BUGSNAG_KEY}`,
    plugins: [new BugsnagPluginReact()],
  });

  BugsnagPerformance.start({ apiKey: `${process.env.REACT_APP_BUGSNAG_KEY}` });
  ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);
};

export const BugsnagProvider = ({ isProd, children }) => {
  if (isProd && !ErrorBoundary) {
    initializeBugsnag();
  }

  if (isProd && ErrorBoundary) {
    return <ErrorBoundary>{children}</ErrorBoundary>;
  }

  return <>{children}</>;
};
