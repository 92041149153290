import React, { useContext, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { formatDateTime } from "utils/dateTime";

import { HttpClientContext } from "components/HttpClient";
import {
  doubleSpace,
  ErrorMessage,
  green,
  orange,
  Paragraph,
  red,
  singleSpace,
} from "components/sharedComponents";
import { STAFFING_MANAGERS } from "screens/ShiftDashboard/data/mockData";
import styled from "styled-components";

const STATE = ["Unresolved", "Resolved"];

export const CalloutDashboard = () => {
  const { get } = useContext(HttpClientContext);
  const [error, setError] = useState("");
  const [shifts, setShifts] = useState([]);

  useEffect(() => {
    const fetchShifts = async () => {
      try {
        const res = await get({
          url: "shifts/",
          params: {
            state: [
              "declined",
              "booking_confirmation_declined",
              "early_reminder_declined",
              "day_before_reminder_declined",
            ],
          },
        });
        setShifts(res.data?.shifts);
      } catch (err) {
        setError(err);
      }
    };

    fetchShifts();
  }, [get]);

  return (
    <StyledCommsList>
      <Container fluid>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <Row className="header">
          <Col xs={{ span: 1 }}>
            <StyledColumn>
              <Paragraph>Date</Paragraph>
            </StyledColumn>
          </Col>
          <Col xs={{ span: 1 }}>
            <StyledColumn>
              <Paragraph>Time</Paragraph>
            </StyledColumn>
          </Col>
          <Col xs={{ span: 2 }}>
            <StyledColumn>
              <Paragraph>Staffing Manager</Paragraph>
            </StyledColumn>
          </Col>
          <Col xs={{ span: 2 }}>
            <StyledColumn>
              <Paragraph>Caregiver</Paragraph>
            </StyledColumn>
          </Col>
          <Col xs={{ span: 2 }}>
            <StyledColumn>
              <Paragraph>Patient</Paragraph>
            </StyledColumn>
          </Col>
          <Col xs={{ span: 2 }}>
            <StyledColumn>
              <Paragraph>Status</Paragraph>
            </StyledColumn>
          </Col>
          <Col xs={{ span: 2 }}>
            <StyledColumn>
              <Paragraph>Actions</Paragraph>
            </StyledColumn>
          </Col>
        </Row>
        {shifts.map((shift, i) => (
          <Callout key={i} {...shift} />
        ))}
      </Container>
    </StyledCommsList>
  );
};

const Callout = ({ id, state, start_date, end_date, provider, recipient }) => {
  const assignedIndex = Math.floor(Math.random() * STAFFING_MANAGERS.length);

  const [assigned, setSelectedAssignee] = useState(STAFFING_MANAGERS[assignedIndex]);
  const [itemState, setItemState] = useState(STATE[0]);

  const onChangeAssignee = (value) => {
    setSelectedAssignee(value);
  };

  const onChangeState = (event) => {
    setItemState(event.target.value);
  };

  const { month, day, hours, minutes, period } = formatDateTime(start_date);
  const startDate = `${month} ${day}`;
  const startTime = `${hours}:${minutes} ${period}`;

  return (
    <div className="table-row">
      <Row>
        <Col xs={{ span: 1 }}>
          <StyledColumn>
            <Paragraph>{startDate}</Paragraph>
          </StyledColumn>
        </Col>
        <Col xs={{ span: 1 }}>
          <StyledColumn>
            <Paragraph>{startTime}</Paragraph>
          </StyledColumn>
        </Col>
        <Col xs={{ span: 2 }}>
          <StyledColumn>
            <AssigneeDropdown assigned={assigned} onChangeAssignee={onChangeAssignee} />
          </StyledColumn>
        </Col>
        <Col xs={{ span: 2 }}>
          <StyledColumn>
            <Paragraph>{`${provider.first_name} ${provider.last_name}`}</Paragraph>
          </StyledColumn>
        </Col>
        <Col xs={{ span: 2 }}>
          <StyledColumn>
            <Paragraph>{`${recipient.first_name} ${recipient.last_name}`}</Paragraph>
          </StyledColumn>
        </Col>

        <Col xs={{ span: 2 }}>
          <StyledColumn className={itemState === "Unresolved" ? "unresolved" : "resolved"}>
            <StatusDropdown currentState={itemState} onChangeState={onChangeState} />
          </StyledColumn>
        </Col>
        <Col xs={{ span: 2 }}>
          <StyledColumn>
            <Paragraph>
              <a href={`/caregiver-callout/${id}`}>Fill Shift</a>
            </Paragraph>
          </StyledColumn>
        </Col>
      </Row>
    </div>
  );
};

const AssigneeDropdown = ({ assigned, onChangeAssignee }) => {
  return (
    <StyledDropdown>
      <select
        id="assignee-select"
        value={assigned}
        onChange={(event) => onChangeAssignee(event.target.value)}
      >
        {STAFFING_MANAGERS.map((assignee, index) => (
          <option key={index} value={assignee}>
            {assignee}
          </option>
        ))}
      </select>
    </StyledDropdown>
  );
};

const StatusDropdown = ({ currentState, onChangeState }) => {
  return (
    <StyledDropdown>
      <select id="state-select" value={currentState} onChange={onChangeState}>
        {STATE.map((s, index) => (
          <option key={index} value={s}>
            {s}
          </option>
        ))}
      </select>
    </StyledDropdown>
  );
};

const StyledCommsList = styled.section`
  .header {
    padding: ${doubleSpace} 0;
    p {
      font-weight: 600;
    }
  }

  .table-row {
    p {
      font-size: 16px;
    }
  }
`;

const StyledColumn = styled.div`
  padding-top: ${doubleSpace};
  padding-bottom: ${doubleSpace};
  text-align: left;

  p.alert {
    color: ${red};
  }

  &.unresolved {
    select {
      color: ${red};
    }
  }

  &.resolved {
    select {
      color: ${green};
    }
  }

  &.cancelled {
    p,
    select {
      color: ${red};
      font-weight: bold;
    }
  }

  &.ok {
    p,
    select {
      color: ${green};
    }
  }

  &.at-risk {
    p,
    select {
      color: ${orange};
    }
  }

  &.not-sent {
    background-color: none;
  }

  button {
    border: none;
    background: none;
    display: inline-block;
    margin-top: ${singleSpace};

    &:hover {
      text-decoration: underline;
    }
  }
`;

const StyledDropdown = styled.div`
  select {
    background: none;
    padding: 5px;
    border-radius: 5px;
    border: none;
    max-width: 100%;
    font-weight: 600;
  }
`;
