import React, { useContext, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { AuthContext } from "components/AuthContext";
import { HttpClientContext } from "components/HttpClient";
import {
  blue,
  doubleSpace,
  ErrorMessage,
  green,
  Input,
  lightGray,
  logoBlue,
  Paragraph,
  PrimaryButton,
  quadSpace,
  red,
  singleSpace,
  Subheader,
} from "components/sharedComponents";
import styled from "styled-components";

export const Signup = () => {
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { loggedIn, login } = useContext(AuthContext);
  const { post } = useContext(HttpClientContext);

  useEffect(() => {
    if (loggedIn) {
      const dest = location?.from?.pathname || "/";
      navigate(dest);
    }
  }, [location, loggedIn, navigate]);

  const validPassword = password === "" || password.length > 11;

  const handleSubmit = async () => {
    setError(null);
    setLoading(true);
    try {
      const res = await post({
        url: "users/",
        payload: {
          email,
          password,
          first_name: firstName,
          last_name: lastName,
          tos_accepted: termsAccepted,
          phone_number: removeNonNumeric(phoneNumber),
        },
      });
      const user = res.data.user;
      user["token"] = res.data.token;
      login(user);
      navigate("/caregiver-status");
    } catch (err) {
      if (err.code === 404) {
        setError(404);
      } else {
        setError(err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const removeNonNumeric = (str) => {
    return str.replace(/\D/g, "");
  };

  return (
    <StyledSignup>
      <Container>
        <Row>
          <Col md={{ offset: 3, span: 6 }} lg={{ offset: 4, span: 5 }}>
            <StyledHeader>
              <Subheader>Welcome to Sanctum</Subheader>
            </StyledHeader>
            <StyledLoginForm>
              <Input
                autoFocus
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="First name"
                type="text"
                autocomplete="given-name"
                value={firstName}
              />
              <Input
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Last name"
                type="text"
                autocomplete="family-name"
                value={lastName}
              />
              <Input
                onChange={(e) => setEmail(e.target.value)}
                placeholder="email@example.com"
                type="email"
                autocomplete="username"
                value={email}
              />
              <Input
                onChange={(e) => setPhoneNumber(e.target.value)}
                placeholder="Phone number"
                type="tel"
                value={phoneNumber}
              />
              <Input
                className={validPassword ? "valid" : "invalid"}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter a password"
                type="password"
                autocomplete="new-password"
                value={password}
              />
              {!validPassword && password ? (
                <Paragraph className="small">
                  To keep your account safe, please use a password with at least 12 characters.
                </Paragraph>
              ) : null}
              <Input
                className={
                  passwordCheck === "" ? "" : password === passwordCheck ? "valid" : "invalid"
                }
                onChange={(e) => setPasswordCheck(e.target.value)}
                placeholder="Confirm password"
                type="password"
                autocomplete="new-password"
                value={passwordCheck}
              />
              <label className="terms-checkbox">
                <Input type="checkbox" onChange={() => setTermsAccepted(!termsAccepted)} />
                <Paragraph>
                  I agree to the{" "}
                  <a
                    href="https://www.hellosanctum.com/terms-and-conditions"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms and Conditions
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://www.hellosanctum.com/privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                </Paragraph>
              </label>
              {error && <ErrorMessage>{error === 404 ? <WaitlistPrompt /> : error}</ErrorMessage>}
              <StyledButton>
                <PrimaryButton
                  disabled={
                    !email ||
                    !password ||
                    password !== passwordCheck ||
                    loading ||
                    !termsAccepted ||
                    !validPassword
                  }
                  onClick={handleSubmit}
                  text={loading ? "Working..." : "Get Started"}
                />
              </StyledButton>
            </StyledLoginForm>
          </Col>
        </Row>
      </Container>
    </StyledSignup>
  );
};

const WaitlistPrompt = () => {
  return (
    <div>
      <Paragraph style={{ margin: 0 }}>
        Whoops! We didn't see you in our list of approved users. To request access, please join our
        waitlist: <Link to="/">https://hellosanctum.com</Link>
      </Paragraph>
    </div>
  );
};

const StyledSignup = styled.div`
  height: 100vh;
  padding: ${quadSpace} 0;

  p {
    margin-bottom: ${doubleSpace};
  }
`;

const StyledHeader = styled.div`
  margin-bottom: ${quadSpace};
  h3 {
    margin-bottom: ${singleSpace};
  }
  h3,
  p {
    font-weight: 400;
  }
`;

const StyledLoginForm = styled.div`
  background-color: white;

  input {
    border: none;
    border-radius: 0;
    box-shadow: none;
    border-bottom: 1px solid ${lightGray};
    margin-bottom: ${doubleSpace};
    width: 80%;
    min-width: 310px;
  }

  input:focus {
    border-bottom: 1px solid ${blue};
  }

  input.invalid {
    border-bottom: 1px solid ${red};
  }

  input.valid {
    border-bottom: 1px solid ${green};
  }

  .terms-checkbox {
    input {
      height: 19px;
    }
    input,
    p {
      display: inline;
      font-size: 14px;
    }

    a {
      color: ${logoBlue};
    }
  }
`;

const StyledButton = styled.div`
  margin: ${quadSpace} 0;
  button {
    width: 280px;
  }
`;
